import React, { useCallback, useMemo, useState } from 'react';
import { format, parse } from 'date-fns';
import { uniqueId } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@mindbaz/app-manager';
import {
  Button,
  Card,
  DataGrid,
  Icon,
  Panel,
  Popup,
  Text,
  LineChart,
  colors,
} from '@mindbaz/ui-v2';

import TitleDescriptionBlock from 'components/common/TitleDescriptionBlock';
import { getDatabasestats, getdailyStats } from 'packages/endpoints/safeSend';
import {
  getPerson,
  getUserInformation,
  isAdmin,
} from 'packages/endpoints/common';

import {
  StyledCards,
  StyledButton,
  StyledRadioButton,
  ChartContainer,
  PopupContainer,
  Flex,
  Link,
} from './styledComponents';

import useDataGrid from './useDataGrid';

import { TCard, TDatabaseStat } from './types';
import { PERIOD_PANEL, periodOptions } from './utils';

const SafeSend = () => {
  const { translate } = useTranslation();

  const [isOpenModal, setOpenModal] = useState(false);

  const { data: isAdminUser } = useQuery(['is-admin'], () => isAdmin());

  const { data: userInformations } = useQuery(['get-user-information'], () =>
    getUserInformation()
  );

  const { data: person } = useQuery(['get-person'], () => getPerson());

  const adminListOfCurrentUser = userInformations.adminList.split(';');

  const periodsPanel = Object.values(PERIOD_PANEL);

  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(
    PERIOD_PANEL.THIRTY_LAST_DAYS
  );

  const { columns } = useDataGrid();

  const formatDateStandard = (date: Date) => format(date, 'dd/MM/yyyy');

  const formatDateISO = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  };

  const datesOfSelectedPeriodFormatISO = useMemo(() => {
    const { startDate, endDate } = periodOptions[selectedPeriod];
    const parsedStartDate = parse(startDate, 'dd/MM/yyyy', new Date());
    const parsedEndDate = parse(endDate, 'dd/MM/yyyy', new Date());
    const start = formatDateISO(parsedStartDate);
    const end = formatDateISO(parsedEndDate);

    return { start, end };
  }, [selectedPeriod]);

  const handleSlectedPeriod = useCallback((period: string) => {
    setSelectedPeriod(period);
  }, []);

  const { data: databaseStats } = useQuery(
    ['database-stats', selectedPeriod],
    () =>
      person &&
      periodOptions[selectedPeriod] &&
      getDatabasestats(
        person.idCompany,
        datesOfSelectedPeriodFormatISO.start,
        datesOfSelectedPeriodFormatISO.end
      ),
    {
      suspense: false,
    }
  );

  const { data: dailyStats } = useQuery(
    ['daily-stats', selectedPeriod],
    () =>
      person &&
      periodOptions[selectedPeriod] &&
      getdailyStats(
        person.idCompany,
        datesOfSelectedPeriodFormatISO.start,
        datesOfSelectedPeriodFormatISO.end
      ),
    {
      suspense: false,
    }
  );

  const dailyStatsFormattedData = useMemo(() => {
    if (dailyStats && dailyStats.result.length > 0) {
      const blackListedEmails = dailyStats.result.map(
        (email: { day: string; nbBlackListedEmails: number }) => ({
          x: email.day,
          y: email.nbBlackListedEmails,
        })
      );
      const suspiciousActions = dailyStats.result.map(
        (email: { day: string; nbSuspiciousActions: number }) => ({
          x: email.day,
          y: email.nbSuspiciousActions,
        })
      );
      return [
        {
          id: translate('safeSend.dailyStats.excludedEmails'),
          data: blackListedEmails,
        },
        {
          id: translate('safeSend.dailyStats.suspiciousActions'),
          data: suspiciousActions,
        },
      ];
    }
    return [];
  }, [dailyStats, translate]);

  const formatEmailsAndActionsByDB = useMemo(() => {
    return (
      databaseStats &&
      databaseStats.result.map((data: TDatabaseStat) => {
        const { currentSubState, stateUntilOrFromDate } = data;

        if (stateUntilOrFromDate === '0001-01-01T00:00:00') {
          return { ...data, currentSubState, id: uniqueId() };
        }
        if (currentSubState === 'deactivated from') {
          return {
            id: uniqueId(),
            nbBlackListedEmails: '-',
            nbSuspiciousActions: '-',
            currentSubState: translate('safeSend.databseStats.fromDate', {
              date: formatDateStandard(new Date(stateUntilOrFromDate)),
            }),
          };
        }
        if (currentSubState === 'activated until') {
          return {
            ...data,
            id: uniqueId(),
            currentSubState: translate('safeSend.databseStats.untilDate', {
              date: formatDateStandard(new Date(stateUntilOrFromDate)),
            }),
          };
        }
        if (currentSubState === 'activated from') {
          return {
            ...data,
            id: uniqueId(),
            currentSubState: translate('safeSend.databseStats.fromDate', {
              date: formatDateStandard(new Date(stateUntilOrFromDate)),
            }),
          };
        }
        return { ...data, currentSubState, id: uniqueId() };
      })
    );
  }, [databaseStats, translate]);

  const formatDateText = () => {
    const { startDate, endDate } = periodOptions[selectedPeriod];
    return translate('safeSend.databseStats.dateRange', {
      startDate,
      endDate,
    });
  };

  const cards = [
    {
      title: translate('safeSend.cards.emails.title'),
      description: translate('safeSend.cards.emails.description', {
        data: databaseStats && databaseStats.totalBlacklistedEmails,
      }),
      icon: 'block',
      data:
        databaseStats &&
        databaseStats.totalBlacklistedEmails.toLocaleString('FR-fr'),
    },
    {
      title: translate('safeSend.cards.actions.title'),
      description: translate('safeSend.cards.actions.description', {
        data: databaseStats && databaseStats.totalSuspiciousActions,
      }),
      icon: 'smart_toy',
      data:
        databaseStats &&
        databaseStats.totalSuspiciousActions.toLocaleString('FR-fr'),
    },
  ];

  const handleOpenPanel = () => {
    setIsOpenPanel(!isOpenPanel);
  };

  const handleRedirection = () => {
    if (isAdminUser)
      window.location.replace(`${process.env.REACT_APP_ADMIN_URL}safesend`);
    else {
      setOpenModal(!isOpenModal);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const chartColors = [colors.SpaceCadet, colors.BlueNCS];

  const translations = useMemo(
    () => ({
      search: translate('table.search'),
      elementsTabCount: translate('table.elementsTabCount'),
    }),
    [translate]
  );

  return (
    <>
      <Flex className="justify-between">
        <TitleDescriptionBlock
          title="safeSend.title"
          description="safeSend.description"
          moreDescription={
            <Link href={translate('safeSend.descriptionLink')}>
              {translate('safeSend.descriptionClickHere')}
            </Link>
          }
        />
        <StyledButton onClick={handleOpenPanel} endIcon="event">
          {formatDateText()}
        </StyledButton>
        <Panel
          title={translate('safeSend.panel.title')}
          description={translate('safeSend.panel.period')}
          open={isOpenPanel}
          onClose={handleOpenPanel}
        >
          {periodsPanel.map((period) => (
            <div key={period}>
              <StyledRadioButton
                type="radio"
                id={period}
                value={translate(`safeSend.panel.${period}`)}
                checked={selectedPeriod === period}
                onChange={() => {
                  handleSlectedPeriod(period);
                }}
              />
              <label htmlFor={period}>
                {translate(`safeSend.panel.${period}`)}
              </label>
            </div>
          ))}
        </Panel>
      </Flex>
      <Flex className="mb-10">
        <StyledCards>
          {cards.map(({ title, description, icon, data }: TCard) => (
            <Card key={title} title={title} footer={description}>
              <Flex className="justify-between">
                <Text variant="h1">{data}</Text>
                <Icon color="secondary" size={30}>
                  {icon}
                </Icon>
              </Flex>
            </Card>
          ))}
        </StyledCards>
      </Flex>
      <ChartContainer>
        {dailyStatsFormattedData && (
          <LineChart
            data={dailyStatsFormattedData}
            colors={chartColors}
            height={250}
            margin={{ left: 50, bottom: 25, top: 20, right: 20 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%dT%H:%M:%S',
              precision: 'day',
              useUTC: false,
            }}
            axisXFormat="%d/%m"
            axisBottom={{
              format: '%d/%m',
              tickValues:
                (selectedPeriod === PERIOD_PANEL.LAST_MONTH ||
                  selectedPeriod === PERIOD_PANEL.CURRENT_MONTH ||
                  selectedPeriod === PERIOD_PANEL.THIRTY_LAST_DAYS) &&
                dailyStats?.result?.length > 15
                  ? 31
                  : 15,
            }}
            enableSlices="x"
          />
        )}
      </ChartContainer>
      <Flex className="justify-between mb-20 mt-50">
        <Flex className="flex-column">
          <Text variant="h3">{translate('safeSend.detailsBDD')}</Text>
          <Text variant="body">{formatDateText()}</Text>
        </Flex>
        <Button
          variant="primary"
          variantColor="DarkPink"
          onClick={handleRedirection}
        >
          {translate('safeSend.updateOffer')}
        </Button>
        <Popup
          onClose={handleCloseModal}
          title={translate('safeSend.popup.title')}
          open={isOpenModal}
        >
          <PopupContainer>
            <Text>
              {translate('safeSend.popup.description', {
                adminList: adminListOfCurrentUser,
              })}
            </Text>
            <Button onClick={handleCloseModal}>
              {translate('safeSend.popup.confirm')}
            </Button>
          </PopupContainer>
        </Popup>
      </Flex>
      <Flex className="pb-4">
        {formatEmailsAndActionsByDB && (
          <DataGrid
            maximumRowActionsDisplayed={3}
            checkboxSelection={false}
            columns={columns}
            rows={formatEmailsAndActionsByDB}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            disableFilters
            translations={translations}
          />
        )}
      </Flex>
    </>
  );
};
export default SafeSend;
